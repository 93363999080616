import React from "react";
import About from './About'
import Services from './Services'
import Condiciones from './Condiciones'
import Contact from './Contact'
import Nav from './Navbar' 
import Main from './Main'

function Container(props) {
  return <div className="w-full bg-transparent">
    {/* {props.children} */}
      <Nav/>
      <Main/>
      <About/>
      <Services/>
      <Condiciones/>
      <Contact/>

    </div>;
}

export default Container;
