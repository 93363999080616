import { BrowserRouter, Route, Routes } from "react-router-dom";
import Terminos from "./components/Terminos";

import Container from "./components/Container";
function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<Container/>}/>
          <Route  path="/terminos" element={<Terminos/>}/>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
