import React from "react";
import terms from "../media/listcheck.svg";
import pattern_blue from "../media/lineas.png";
import {Link} from 'react-router-dom'

function Testimonials() {
  return (
    <div
      className="bg-blue-800 h-80"
      style={{ backgroundImage: `url(${pattern_blue})` }}
      id="terms"
    >
      <div className="items-center pb-8 ">
        <div className=" text-center pt-10 ">
          <h1 className="text-center text-2xl md:text-4xl  text-white">
            Términos y condiciones
          </h1>
          <div className="block md:flex items-center justify-center mt-4">
                       
            <Link
            className="cursor-pointer w-1/2 text-xl md:text-3xl text-white underline"
            to="/terminos"
            exact="true"
          >
      Ver terminos y condiciones
          </Link>
            <img
              className="w-1/3 mx-auto mt-4  md:w-[140px]"
              src={terms}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Testimonials;
